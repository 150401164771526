import {Injectable} from '@angular/core';
import {Api} from "../provider/api.service";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PermessiService extends Api {

    private URL = environment.apiUrl;
    permissions: any = []; // Store the actions for which this user has permission
    currentUser: any;
    public permissionConfig: any;


    hasPermission(authGroup: string): boolean {

        this.permissions = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('permessi') as string)
            : {};
        const ruolo: any = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user') as string)
            : {};


       for (let ruol of ruolo?.group) {
            if (ruol?.idUtenteGruppo == 1) {
                return true;
            }
       }


        const value = JSON.parse(JSON.stringify(this.permissions))[authGroup];

        if (value == undefined) {
            return false;
        } else {
            return value;
        }
    }

    getPermissionConfig() {
        return false;
    }

    initializePermissions() {
        this.http.get(this.URL + 'permission/permission-me').subscribe((res: any) => {
            this.permissionConfig = res;
            localStorage.setItem('permessi', JSON.stringify(res.data));
        });
    }
}
