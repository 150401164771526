import { Injectable } from '@angular/core';
import {Api} from "../provider/api.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Api{


    login(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'auth/login', params);
    }

    register(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'auth/register', params);
    }

    logout(): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'auth/logout', {});
    }

    me(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'auth/profilo');
    }

    getPermission(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'permission/permission-me');

    }
}
