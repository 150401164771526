
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {MessageService} from "primeng/api";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    protected userTokenDetail: any = {};

    constructor(
        private messageService: MessageService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (isPlatformBrowser(this.platformId)) {
            this.userTokenDetail = localStorage.getItem('token');
        }
        if (isPlatformServer(this.platformId)) {
        }
        if (this.userTokenDetail) {
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.userTokenDetail
                }
            });
        }

        return next.handle(req).pipe(
            map((user: any) => {
                if (user instanceof HttpResponse) {
                    const response = user.body;
                    if (
                        response.message &&
                        response.message !== '' &&
                        req.method !== 'GET' &&
                        response.message !== 'Redirect to this url.'
                    ) {
/*
                        this.showSuccess(user.body.message);
*/
                    }
                }
                return user;
            }),
            catchError(response => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
                switch (response.status) {
                    case 400:
                        this.handleBadRequest(response);
                        break;
                    case 401:
                        this.handleUnAuthorized();
                        break;
                    case 403:
                        this.handleUnAuthorized();
                        break;
                    case 500:
                        this.handleServerError(response);
                        break;
                    default:
                        break;
                }
                return throwError(response);
            })
        );
    }

    /**
     * Shows notification errors when server response status is 401
     *
     * @params error
     */
    private handleBadRequest(responseBody: any): void {
        if (responseBody.error) {
            try {
                const bodyParsed = responseBody.error;
                this.handleErrorMessages(bodyParsed);
            } catch (error) {}
        }
    }

    private handleErrorMessages(response: any): void {
        if (!response) {
            return;
        } else {
            this.showNotificationError(response.message);
        }
    }

    /**
     * redirect to login if un authorized
     *
     */
    private handleUnAuthorized() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('storeUser');
            localStorage.removeItem('storeUserToken');
            localStorage.removeItem('compareId');
            localStorage.clear();
            this.router.navigate(['/auth/access']);
        }
    }
    handleServerError(responseBody: any) {
        console.log(responseBody)
        this.showNotificationError('Errore interno del server');
    }

    private showNotificationError(message: string): void {
        this.messageService.add({
            severity: 'error',
            summary: 'Errore',
            detail: message
        });
    }

    /*private showSuccess(message: string, status?: string) {
        this.messageService.add({
            severity: status,
            summary: status === 'success' ? 'Successo' : 'Attenzione',
            detail: message
        });
    }*/
}
