import {Component, OnInit} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {AuthService} from "../core/auth/auth.service";
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {PermessiService} from "../core/permessi/permessi.service";

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit  {

    user = JSON.parse(localStorage.getItem('user') || '{}')


    constructor(private messageService: MessageService, private permessiService: PermessiService, public layoutService: LayoutService, private authService: AuthService, private router: Router) { }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    ngOnInit(): void {
        this.permessiService.initializePermissions();
        this.authService.me().subscribe((res: any) => {
            this.user = res.data;
            console.log(this.user)
        });
    }

    logout() {
        this.authService.logout().subscribe((res: any) => {
            if (res.status === 'success') {
                this.router.navigate(['/auth/login']);
                localStorage.clear();
            } else {
                this.messageService.add({severity: 'error', summary: 'Errore', detail: res.message});
            }
        })
    }

    get getAdmin() {
        return true;
       // return this.user?.UtenteGruppo.find((item: any) => item.nome === 'Admin');
    }

    get getName() {
        return ''
/*
        return this.user?.nome + ' ' + this.user?.cognome;
*/
    }
}
