import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-snake',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './snake.component.html',
  styleUrl: './snake.component.scss'
})
export class SnakeComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('gameCanvas', { static: false }) gameCanvas!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  private snake: { x: number, y: number }[] = [];
  private food: { x: number, y: number } = { x: 0, y: 0 };
  private direction: string = 'right';
  private gridSize: number = 20;
  private gridWidth: number = 20;
  private gridHeight: number = 20;
  score: number = 0;
  isGameRunning: boolean = false;
  gameOver: boolean = false;
  private gameLoop: any;

  urlSnake: boolean = false;
  private routerSubscription: Subscription | undefined;

  constructor(private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkUrl();
    });

    // Initial check
    this.checkUrl();
  }

  ngAfterViewInit() {
    if (this.gameCanvas) {
      const canvas = this.gameCanvas.nativeElement;
      const context = canvas.getContext('2d');
      if (!context) {
        console.error('Unable to get 2D context for canvas');
        return;
      }
      this.ctx = context;
      this.resetGame();
      this.cdr.detectChanges(); // Trigger change detection
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.endGame();
  }

  checkUrl(): void {
    const currentUrl = this.router.url;
    if (currentUrl === '/' || currentUrl === '#' || currentUrl === '#/') {
      console.log('OLAF');
      this.urlSnake = true;
      if (this.ctx) { // Only start the game if the canvas is ready
        this.startGame();
      }
    } else {
      this.urlSnake = false;
      this.endGame(); // Stop the game if it's running
    }
    this.cdr.detectChanges();
  }

  resetGame() {
    this.snake = [{ x: 10, y: 10 }];
    this.food = this.generateFood();
    this.direction = 'right';
    this.score = 0;
    this.gameOver = false;
  }

  startGame() {
    if (this.isGameRunning) {
      clearInterval(this.gameLoop); // Clear existing game loop
    }
    this.resetGame();
    this.isGameRunning = true;
    this.gameLoop = setInterval(() => this.gameStep(), 100);
  }

  gameStep() {
    if (!this.ctx || !this.isGameRunning) return; // Safety check

    const head = { ...this.snake[0] };
    switch (this.direction) {
      case 'up': head.y--; break;
      case 'down': head.y++; break;
      case 'left': head.x--; break;
      case 'right': head.x++; break;
    }

    if (this.checkCollision(head)) {
      this.endGame();
      return;
    }

    this.snake.unshift(head);

    if (head.x === this.food.x && head.y === this.food.y) {
      this.score++;
      this.food = this.generateFood();
    } else {
      this.snake.pop();
    }

    this.draw();
  }

  checkCollision(head: { x: number, y: number }): boolean {
    return (
      head.x < 0 || head.x >= this.gridWidth ||
      head.y < 0 || head.y >= this.gridHeight ||
      this.snake.slice(1).some(segment => segment.x === head.x && segment.y === head.y)
    );
  }

  generateFood(): { x: number, y: number } {
    let food: { x: number; y: number; };
    do {
      food = {
        x: Math.floor(Math.random() * this.gridWidth),
        y: Math.floor(Math.random() * this.gridHeight)
      };
    } while (this.snake.some(segment => segment.x === food.x && segment.y === food.y));
    return food;
  }

  draw() {
    if (!this.ctx) return; // Safety check

    this.ctx.clearRect(0, 0, this.gameCanvas.nativeElement.width, this.gameCanvas.nativeElement.height);

    // Draw snake
    this.ctx.fillStyle = '#10B981'; // Tailwind green-500
    this.snake.forEach(segment => {
      this.ctx.fillRect(segment.x * this.gridSize, segment.y * this.gridSize, this.gridSize, this.gridSize);
    });

    // Draw food
    this.ctx.fillStyle = '#EF4444'; // Tailwind red-500
    this.ctx.fillRect(this.food.x * this.gridSize, this.food.y * this.gridSize, this.gridSize, this.gridSize);
  }

  endGame() {
    clearInterval(this.gameLoop);
    this.isGameRunning = false;
    this.gameOver = true;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.isGameRunning) return;

    switch (event.key) {
      case 'ArrowUp':
        if (this.direction !== 'down') this.direction = 'up';
        break;
      case 'ArrowDown':
        if (this.direction !== 'up') this.direction = 'down';
        break;
      case 'ArrowLeft':
        if (this.direction !== 'right') this.direction = 'left';
        break;
      case 'ArrowRight':
        if (this.direction !== 'left') this.direction = 'right';
        break;
    }
  }
}