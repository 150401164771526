import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { SpinnerService } from "./core/spinner/spinner.service";
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    @HostBinding('class') className = '';
    lang = {
        startsWith: "Inizia con",
        contains: "Contiene",
        notContains: "Non contiene",
        endsWith: "Finisce con",
        equals: "Uguale a",
        notEquals: "Diverso da",
        noFilter: "Nessun filtro",
        lt: "Minore di",
        lte: "Minore o uguale a",
        gt: "Maggiore di",
        gte: "Maggiore o uguale a",
        is: "È",
        isNot: "Non è",
        before: "Prima di",
        after: "Dopo",
        dateIs: "La data è",
        dateIsNot: "La data non è",
        dateBefore: "La data è prima di",
        dateAfter: "La data è dopo",
        clear: "Pulisci",
        apply: "Applica",
        matchAll: "Corrispondi tutti",
        matchAny: "Corrispondi uno qualsiasi",
        addRule: "Aggiungi regola",
        removeRule: "Rimuovi regola",
        accept: "Sì",
        reject: "No",
        choose: "Scegli",
        upload: "Carica",
        cancel: "Annulla",
        dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
        dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
        monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
        monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
        dateFormat: "dd/mm/yy",
        firstDayOfWeek: 0,
        today: "Oggi",
        weekHeader: "Sett",
        weak: "Debole",
        medium: "Medio",
        strong: "Forte",
        passwordPrompt: "Inserisci una password",
        emptyMessage: "Nessun risultato trovato",
        emptyFilterMessage: "Nessun risultato trovato"
    }
    isLoading: boolean = false;
    // private routerSubscription: Subscription | undefined;

    constructor(
        private primengConfig: PrimeNGConfig,
        private _elementRef: ElementRef,
        public spinnerService: SpinnerService,
        private router: Router
    ) {
        // Rileva il tema preferito del browser
        const darkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

        // Aggiorna la classe CSS per il tema corretto
        this.className = darkTheme ? 'dark-theme' : 'light-theme';
    }

    ngOnInit(): void {
        this._elementRef.nativeElement.removeAttribute("ng-version");
        this.primengConfig.setTranslation(this.lang);
        this.primengConfig.ripple = true;
        this.spinnerService.isLoading$.subscribe(isLoading => {
            this.isLoading = isLoading;
        });

        // Subscribe to router events
        // this.routerSubscription = this.router.events.pipe(
        //     filter(event => event instanceof NavigationEnd)
        // ).subscribe(() => {
        //     console.log('ciao');

        // });

        // Initial log
        console.log('ciao');
    }

    // ngOnDestroy(): void {
    //     if (this.routerSubscription) {
    //         this.routerSubscription.unsubscribe();
    //     }
    // }
}