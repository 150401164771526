import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from "./core/auth/auth.guard";
import { TemplateModule } from "./demo/components/template/template.module";
import { SnakeComponent } from './components/snake/snake.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [


    {

        path: '', component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'utilities',
                data: { breadcrumb: 'Utilities' },
                loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule)
            },
            {
                path: 'pages',
                data: { breadcrumb: 'Pagine' },
                loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule)
            },
            {
                path: 'apps',
                data: { breadcrumb: 'Apps' },
                loadChildren: () => import('./demo/components/apps/apps.module').then(m => m.AppsModule)
            },
            {
                path: 'influencer-admin',
                data: { breadcrumb: 'Influencer Admin' },
                loadChildren: () => import('./demo/components/influencer-admin/influencer-admin.module').then(m => m.InfluencerAdminModule)
            },
        ]
    },

    { path: 'snake', component: SnakeComponent },

    {
        path: 'impostazioni',
        data: { breadcrumb: 'Impostazioni' },
        loadChildren: () => import('./demo/components/impostazioni/impostazioni.module').then(m => m.ImpostazioniModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'template',
        data: { breadcrumb: 'Impostazioni' },
        loadChildren: () => import('./demo/components/template/template.module').then(m => m.TemplateModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'auth',
        data: { breadcrumb: 'Auth' },
        loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'no-auth',
        data: { breadcrumb: 'No Auth' },
        loadChildren: () => import('./demo/components/no-auth/no-auth.module').then(m => m.NoAuthModule)
    },
    {
        path: 'notfound',
        loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule)
    },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
