<div class="layout-container" [ngClass]="containerClass">
    <app-sidebar></app-sidebar>
    <div class="layout-content-wrapper">
        <app-modal></app-modal>
        <app-topbar></app-topbar>
        <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>
        <p-toast></p-toast>
        <div > <!--class="layout-content"-->
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profilemenu></app-profilemenu>
    <app-config></app-config>
    <div class="layout-mask"></div>

    
</div>



