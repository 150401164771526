import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(value: any[], key: string): any[] {
    const groupedElements = value.reduce((acc, obj) => {
      const property = obj[key];
      if (!acc[property]) {
        acc[property] = [];
      }
      acc[property].push(obj);
      return acc;
    }, {});

    return Object.entries(groupedElements).map(([key, value]) => ({ key, value }));
  }
}
