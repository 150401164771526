import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  show() {
    document.body.style.overflow = 'hidden';
    this.isLoadingSubject.next(true);
  }

  hide() {
    this.isLoadingSubject.next(false);
    document.body.style.overflow = 'auto';
  }
}
