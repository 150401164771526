import { Component, OnInit } from '@angular/core';
import { PermessiService } from "../core/permessi/permessi.service";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    allMenu: any[] = []

    constructor(private permessiService: PermessiService) {
    }

    async ngOnInit() {

        this.allMenu = [
            /*{
                label: 'Dashboards',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Analytics',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: ['/dashboards/analytics'],
                        permission: 'analytics'
                    },
                    {
                        label: 'E-Commerce',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/'],
                        permission: 'dashboard'
                    },
                    {
                        label: 'Calendar',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['/dashboards/calendar'],
                        permission: 'calendar'
                    }
                ]
            },*/
            {
                label: 'impostazioni',
                icon: 'pi pi-gear',
                items: [
                    {
                        label: 'Snake',
                        icon: 'pi pi-wave-pulse',
                        routerLink: ['/snake'],
                        permission: ''
                    },
                    {
                        label: 'Permessi',
                        icon: 'pi pi-fw pi-lock',
                        routerLink: ['/pages/impostazioni'],
                        permission: 'impostazioni'
                    },
                ]
            },
            {
                label: 'Zendesk',
                icon: 'pi pi-ticket',
                items: [
                    {
                        label: 'Utenti',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/pages/zendesk/utenti/list'],
                        permission: 'zendesk-utenti'
                    },
                    {
                        label: 'Ticket',
                        icon: 'pi pi-fw pi-ticket',
                        routerLink: ['/pages/zendesk/utenti/list'],
                        permission: 'zendesk-ticket'
                    },
                ]
            },
            {
                label: 'Strumenti',
                icon: 'pi pi-wrench',
                items: [

                    {
                        label: 'Anagrafiche',
                        icon: 'pi pi-wrench',
                        routerLink: ['/pages/anagrafiche/list'],
                        permission: 'anagrafiche'
                    },
                    {
                        label: 'Circuiti',
                        icon: 'pi pi-wrench',
                        routerLink: ['/pages/circuiti/list'],
                        permission: 'circuiti'
                    },
                    {
                        label: 'Veicoli',
                        icon: 'pi pi-car',
                        routerLink: ['/pages/veicoli/list'],
                        permission: 'veicoli'
                    },
                    {
                        label: 'Eventi',
                        icon: 'pi pi-calendar',
                        routerLink: ['/pages/eventi/list'],
                        permission: 'eventi'
                    },
                    {
                        label: 'Fasce',
                        icon: 'pi pi-calendar',
                        routerLink: ['/pages/fasce/list'],
                        permission: 'fasce'
                    },
                    {
                        label: 'Media',
                        icon: 'pi pi-folder',
                        routerLink: ['/apps/files'],
                        permission: 'fasce'
                    },
                    {
                        label: 'Cofanetti',
                        icon: 'pi pi-euro',
                        items: [
                            {
                                label: 'Cofanetti',
                                icon: 'pi pi-cog',
                                routerLink: ['/pages/cofanetti/list'],
                                permission: 'cofanetti'
                            },
                            {
                                label: 'Seriali',
                                icon: 'pi pi-cog',
                                routerLink: ['/pages/cofanetti/seriale/list'],
                                permission: 'cofanetti-seriali'
                            },
                            {
                                label: 'Servizi',
                                icon: 'pi pi-cog',
                                routerLink: ['/pages/cofanetti/servizi/list'],
                                permission: 'servizi'
                            },
                            {
                                label: 'Spedizioni',
                                icon: 'pi pi-cog',
                                routerLink: ['/pages/cofanetti/spedizioni'],
                                permission: 'spedizione'
                            },
                        ]
                    },
                ]
            },
            {
                label: 'E-commerce',
                icon: 'pi pi-box',
                items: [
                    {
                        label: 'Ordini',
                        icon: 'pi pi-box',
                        routerLink: ['/pages/e-commerce/ordini/list'],
                        permission: 'ordini'
                    },
                    {
                        label: 'Report',
                        icon: 'pi pi-box',
                        routerLink: ['/pages/e-commerce/report'],
                        permission: 'report'
                    },
                    {
                        label: 'Carrelli abbandonato',
                        icon: 'pi pi-cart-arrow-down',
                        routerLink: ['/pages/e-commerce/cart-abandoned/list'],
                        permission: 'carrello'
                    },




                    //Prima era feedBack
                    {
                        label: 'Coupon',
                        icon: 'pi pi-box',
                        routerLink: ['/pages/e-commerce/Coupon'],
                        permission: 'coupon'
                    },

                    {
                        label: 'Gift-Card',
                        icon: 'pi pi-box',
                        routerLink: ['/pages/e-commerce/Gift-Card'],
                        permission: 'coupon'
                    },
                    //


                    // {
                    //     label: 'Coupon',
                    //     icon: 'pi pi-ticket',
                    //     routerLink: ['/pages/e-commerce/coupon/list'],
                    //     permission: 'coupon'
                    // },







                    {
                        label: 'Impostazioni',
                        icon: 'pi pi-cog',
                        routerLink: ['/pages/e-commerce/impostazioni'],
                        permission: 'impostazioni'
                    },
                    /* {
                         label: 'Carrello abbandonato',
                         icon: 'pi pi-shopping-cart',
                         routerLink: ['/pages/e-commerce/carrello-abbandonato/list'],
                         permission: 'carrello-abbandonato'
                     },
                     {
                         label: 'Report',
                         icon: 'pi pi-chart-bar',
                         routerLink: ['/pages'],
                         permission: 'e-commerce-report'
                     },
                     {
                         label: 'Corrieri',
                         icon: 'pi pi-truck',
                         routerLink: ['/pages'],
                         permission: 'e-commerce-corrieri'
                     },*/

                    /* {
                         label: 'Servizi',
                         icon: 'pi pi-ticket',
                         routerLink: ['/pages/e-commerce/servizi/list'],
                         permission: 'e-commerce-servizi'
                     },
                     {
                         label: 'Bundle',
                         icon: 'pi pi-ticket',
                         routerLink: ['/pages/e-commerce/bundle/list'],
                         permission: 'e-commerce-bundle'
                     }*/
                ]
            },
            {
                label: 'Influencer Marketing',
                icon: 'pi pi-wrench',
                items: [
                    {
                        label: 'Influencer',
                        icon: 'pi pi-tags',
                        routerLink: ['/pages/influencer/list'],
                        permission: 'influencer'
                    },
                    {
                        label: 'Agenzia',
                        icon: 'pi pi-tags',
                        routerLink: ['/pages/agenzie/list'],
                        permission: 'agenzia'
                    },
                    {
                        label: 'Accordi',
                        icon: 'pi pi-users',
                        routerLink: ['/pages/influencer/accordi/list'],
                        permission: 'accordi'
                    },
                    {
                        label: 'Calcolatore',
                        icon: 'pi pi-euro',
                        items: [
                            {
                                label: 'Parametri',
                                icon: 'pi pi-cog',
                                routerLink: ['/pages/influencer/calcolatore/list'],
                                permission: 'parametri'
                            },
                            {
                                label: 'Simulatore',
                                icon: 'pi pi-euro',
                                routerLink: ['/pages/influencer/simulatore/list'],
                                permission: 'simulatore'
                            },
                            {
                                label: 'Social',
                                icon: 'pi pi-instagram',
                                routerLink: ['/pages/influencer/social/list'],
                                permission: 'social'
                            },
                            {
                                label: 'Tipo Contenuti',
                                icon: 'pi pi-mobile',
                                routerLink: ['/pages/influencer/contenuti/list'],
                                permission: 'contenuti'
                            },
                            {
                                label: 'Argomenti',
                                icon: 'pi pi-book',
                                routerLink: ['/pages/influencer/argomenti/list'],
                                permission: 'argomenti'
                            },
                        ]
                    },
                ]
            },
            {
                label: 'Influencer',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Profilo',
                        icon: 'pi pi-user',
                        routerLink: ['/influencer-admin/profilo'],
                        permission: 'admin-influencer-profilo'
                    },
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-th-large',
                        routerLink: ['/influencer-admin/dashboard'],
                        permission: 'admin-influencer-dashboard'
                    },
                    {
                        label: 'Accordi',
                        icon: 'pi pi-money-bill',
                        routerLink: ['/influencer-admin/accordi'],
                        permission: 'admin-influencer-accordi'
                    },
                ]
            },
            {
                label: 'Blog',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Posts',
                        icon: 'pi pi-user',
                        routerLink: ['/pages/blog/list'],
                        permission: 'ordini'
                    },
                ]
            },
            {
                label: 'Qr Scanner',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Validate',
                        icon: 'pi pi-user',
                        routerLink: ['/pages/qr-scanner/validate'],
                        permission: 'qr-scanner-valida'
                    },
                ]
            }
        ];

        await this.permissionMenuList();

    }

    async permissionMenuList() {

        this.model = this.allMenu.map(menuItem => {
            if (menuItem.items) {
                const filteredItems = menuItem.items.filter((submenuItem: {
                    permission: { toString: () => any; };
                }) => {
                    if (submenuItem.permission) {
                        const permissionName = submenuItem.permission.toString();
                        return this.permessiService.hasPermission(permissionName);
                    } else {
                        return true;
                    }
                }).map((submenuItem: any) => {
                    if (submenuItem.items) {
                        const filteredSubitems = submenuItem.items.filter((subsubmenuItem: any) => {
                            if (subsubmenuItem.permission) {
                                const permissionName = subsubmenuItem.permission.toString();
                                return this.permessiService.hasPermission(permissionName);
                            } else {
                                return true;
                            }
                        });
                        if (filteredSubitems.length > 0) {
                            return { ...submenuItem, items: filteredSubitems };
                        } else {
                            return null;
                        }
                    } else {
                        return submenuItem;
                    }
                }).filter((submenuItem: any) => submenuItem !== null);

                if (filteredItems.length > 0) {
                    return { ...menuItem, items: filteredItems };
                } else {
                    return null;
                }
            } else {
                if (menuItem.permission) {
                    const permissionName = menuItem.permission.toString();
                    if (this.permessiService.hasPermission(permissionName)) {
                        return menuItem;
                    } else {
                        return null;
                    }
                } else {
                    return menuItem;
                }
            }
        }).filter(menuItem => menuItem !== null);
    }
}
