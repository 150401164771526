import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {isPlatformBrowser} from "@angular/common";
import {PermessiService} from "../permessi/permessi.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(
      private router: Router,
      public permessiService: PermessiService,
      @Inject(PLATFORM_ID) private platformId: Object
  ) { }


  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.checkLogin(state.url, route.data['permission'], route.data['permissionForHeader'], route.data['root']);
  }


  checkLogin(url: string, rolePermission: string, headerPermission: string, rootModule: string): Promise<boolean> | boolean {
    let permission: any
    let currentUser: any;
    if (isPlatformBrowser(this.platformId)) {
      currentUser = JSON.parse(localStorage.getItem('user') as string);
    }

    if (currentUser) {
      if (url === '/auth/login' || url === '/auth/forgot-password') {
        // Navigate to the login page with extras
        this.router.navigate(['/dashboard']);
        return false;
      }
      if (rolePermission) {
        if (this.permessiService.hasPermission(rolePermission)) {
          console.log('permission')
          return true;
        } else {
          this.router.navigate(['/auth/access']);
          console.log('no permission')
          return false;
        }
      }

      // default route set based on permission slug
      if (headerPermission) {
        if (this.permessiService.hasPermission(headerPermission)) {
          return true;
        } else {
          if (permission) {
            if (rootModule) { // if root module is set then redirect to that module
              const found = permission.find((data: { permission: any; }) => {
                return data.permission;
              });
              if (found) {
                this.router.navigate([found.url]);
              } else {
                this.router.navigate(['/auth/access']);
              }
              return false;
            }
            return true;
          }
        }
      }

      return true;
    } else {
      if (url === '/auth/login' || url === '/auth/forgot-password') {
        return true;
      }
    }
    this.router.navigate(['/auth/login']);
    return false;
  }

  
}
